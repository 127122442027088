import './App.css';
import fone from './assets/hhh3.png';
import tg from './assets/tg.svg';
import dexc from './assets/dexc.png';
import dext from './assets/dextools.svg';
import x from './assets/x.png';
import logo from './assets/fone.png';

function App() {
  return (
    <div className="App">
      <img className="char" src={fone} alt="" />
      <div className='container'>
        <div className='foned'>
          <img src={logo} alt="" />
          <div className="socials">
            <a target={'_blank'} href='https://twitter.com/thizizfone'><img src={x} /></a>
            <a target={'_blank'} href='https://t.me/fonesolana'><img src={tg} /></a>
            <a target={'_blank'} href='https://dexscreener.com/solana/gv9dprrrg8hexyvwcedqcctkuz837qbmaskcxegfg3xm'><img src={dexc} /></a>
            <a target={'_blank'} href='https://www.dextools.io/app/en/solana/pair-explorer/GV9dPrrRg8heXyVWcEDQcCTkuz837qbMaskcxEGfG3xm?t=1713121046664'><img src={dext} /></a>
          </div>
          <p>CA: CLVcChuNer8AotPpoTWqRCaTBZ63H2bgUmqQs9ZhTGub</p>
          <p>For business: evorytzingizfone@gmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default App;
